<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddAsset()"
          >Agregar Activo</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Vehículos: <b>{{ assets.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="assets"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Assets"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_MARC}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        default-sort="CVE_VEHI"
      >
        <template #empty>
          <div class="has-text-centered">No hay activos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddAssetModal :assetInformation="props.row" />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddAssetModal from "./AddAssetModal";

// @ is an alias to /src
export default {
  name: "AssetsList",
  components: {
    AddAssetModal,
  },
  data() {
    return {
      perPage: 50,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ACTIVO",
          label: "Clave del activo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESC_ACTIVO",
          label: "Descripción del activo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PRECIO",
          label: "Precio del activo",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "CANTIDAD",
          label: "Cantidad del activo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PAGADO",
          label: "Cantidad pagada del activo",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "RESTA",
          label: "Cantidad restante a pagar del activo",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
    };
  },
  async created() {},
  methods: {
    openAddAsset() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddAssetModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    assets() {
      return this.$store.getters.ASSETS;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
