var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[(!_vm.assetInformation)?_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Agregar Activo ")]):_vm._e(),(!_vm.assetInformation)?_c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])]):_vm._e(),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Clave del activo (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.assetRequiredErrors.CLAVE_ACTIVO },"message":{
        'La clave del activo no es valida': _vm.assetRequiredErrors.CLAVE_ACTIVO,
      }}},[_c('b-input',{attrs:{"disabled":_vm.assetInformation,"placeholder":"Ejemplo: 123","expanded":"","icon":"card","required":""},model:{value:(_vm.newAsset.CLAVE_ACTIVO),callback:function ($$v) {_vm.$set(_vm.newAsset, "CLAVE_ACTIVO", $$v)},expression:"newAsset.CLAVE_ACTIVO"}})],1),_c('b-field',{attrs:{"label":"Descripción (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.assetRequiredErrors.DESC_ACTIVO },"message":{
        'La descripción no es valida': _vm.assetRequiredErrors.DESC_ACTIVO,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 888-AAA","expanded":"","icon":"car-settings","required":""},model:{value:(_vm.newAsset.DESC_ACTIVO),callback:function ($$v) {_vm.$set(_vm.newAsset, "DESC_ACTIVO", $$v)},expression:"newAsset.DESC_ACTIVO"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Precio (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.assetRequiredErrors.PRECIO },"message":{
        'El precio no es valido': _vm.assetRequiredErrors.PRECIO,
      }}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":2,"placeholder":"Ejemplo: 123","icon":"cash-100","required":true,"disabled":false},model:{value:(_vm.newAsset.PRECIO),callback:function ($$v) {_vm.$set(_vm.newAsset, "PRECIO", $$v)},expression:"newAsset.PRECIO"}})],1),_c('b-field',{attrs:{"label":"Cantidad (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.assetRequiredErrors.CANTIDAD },"message":{
        'La cantidad no es valida': _vm.assetRequiredErrors.CANTIDAD,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 888-AAA","expanded":"","icon":"car-2-plus","required":""},model:{value:(_vm.newAsset.CANTIDAD),callback:function ($$v) {_vm.$set(_vm.newAsset, "CANTIDAD", $$v)},expression:"newAsset.CANTIDAD"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Cantidad Pagada (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.assetRequiredErrors.PAGADO },"message":{
        'La cantidad pagada no es valida': _vm.assetRequiredErrors.PAGADO,
      }}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":2,"placeholder":"Ejemplo: 123","icon":"currency-usd","required":true,"disabled":false},model:{value:(_vm.newAsset.PAGADO),callback:function ($$v) {_vm.$set(_vm.newAsset, "PAGADO", $$v)},expression:"newAsset.PAGADO"}})],1),_c('b-field',{attrs:{"label":"Cantidad restante a pagar (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.assetRequiredErrors.RESTA },"message":{
        'La cantidad restante a pagar no es valida':
          _vm.assetRequiredErrors.RESTA,
      }}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":2,"placeholder":"Ejemplo: 123","icon":"currency-usd-off","required":true,"disabled":false},model:{value:(_vm.newAsset.RESTA),callback:function ($$v) {_vm.$set(_vm.newAsset, "RESTA", $$v)},expression:"newAsset.RESTA"}})],1)],1),(_vm.assetInformation)?_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveAsset(1)}}},[_vm._v("Actualizar activo")]),_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){_vm.startDelete(
          'danger',
          function () {
            _vm.deleteAsset();
          },
          'activo',
          'el activo seleccionado'
        )}}},[_vm._v("Borrar activo")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1):_vm._e(),(!_vm.assetInformation)?_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveAsset(0)}}},[_vm._v("Guardar activo")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }