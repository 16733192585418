<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!assetInformation">
      Agregar Activo
    </h1>

    <div v-if="!assetInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave del activo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': assetRequiredErrors.CLAVE_ACTIVO }"
        :message="{
          'La clave del activo no es valida': assetRequiredErrors.CLAVE_ACTIVO,
        }"
      >
        <b-input
          :disabled="assetInformation"
          placeholder="Ejemplo: 123"
          expanded
          icon="card"
          v-model="newAsset.CLAVE_ACTIVO"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Descripción (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': assetRequiredErrors.DESC_ACTIVO }"
        :message="{
          'La descripción no es valida': assetRequiredErrors.DESC_ACTIVO,
        }"
      >
        <b-input
          placeholder="Ejemplo: 888-AAA"
          expanded
          icon="car-settings"
          v-model="newAsset.DESC_ACTIVO"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Precio (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': assetRequiredErrors.PRECIO }"
        :message="{
          'El precio no es valido': assetRequiredErrors.PRECIO,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="2"
          placeholder="Ejemplo: 123"
          icon="cash-100"
          :required="true"
          v-model="newAsset.PRECIO"
          :disabled="false"
        />
      </b-field>
      <b-field
        label="Cantidad (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': assetRequiredErrors.CANTIDAD }"
        :message="{
          'La cantidad no es valida': assetRequiredErrors.CANTIDAD,
        }"
      >
        <b-input
          placeholder="Ejemplo: 888-AAA"
          expanded
          icon="car-2-plus"
          v-model="newAsset.CANTIDAD"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad Pagada (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': assetRequiredErrors.PAGADO }"
        :message="{
          'La cantidad pagada no es valida': assetRequiredErrors.PAGADO,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="2"
          placeholder="Ejemplo: 123"
          icon="currency-usd"
          :required="true"
          v-model="newAsset.PAGADO"
          :disabled="false"
        />
      </b-field>
      <b-field
        label="Cantidad restante a pagar (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': assetRequiredErrors.RESTA }"
        :message="{
          'La cantidad restante a pagar no es valida':
            assetRequiredErrors.RESTA,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="2"
          placeholder="Ejemplo: 123"
          icon="currency-usd-off"
          :required="true"
          v-model="newAsset.RESTA"
          :disabled="false"
        />
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="assetInformation">
      <b-button type="is-success" @click="saveAsset(1)"
        >Actualizar activo</b-button
      >
      <b-button
        type="is-danger"
        @click="
          startDelete(
            'danger',
            function () {
              deleteAsset();
            },
            'activo',
            'el activo seleccionado'
          )
        "
        >Borrar activo</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!assetInformation">
      <b-button type="is-success" @click="saveAsset(0)"
        >Guardar activo</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "@/utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import {
  fillObjectForm,
  openConfirmationModalError,
} from "@/utils/component-fns";
// @ is an alias to /src
export default {
  name: "AddAssetModal",
  props: ["assetInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      newAsset: {
        CLAVE_ACTIVO: "",
        DESC_ACTIVO: "",
        PRECIO: 0,
        CANTIDAD: 1,
        PAGADO: 0,
        RESTA: 0,
      },
      assetRequiredErrors: {
        CLAVE_ACTIVO: false,
        DESC_ACTIVO: false,
        PRECIO: false,
        CANTIDAD: false,
        PAGADO: false,
        RESTA: false,
      },
    };
  },
  mounted() {
    // Fill the brand information in form fields
    if (this.assetInformation) {
      // Fill the form with received information
      fillObjectForm(this.assetInformation, this.newAsset);
      this.newAsset._id = this.assetInformation._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates a new brand depending on the type
     */
    async saveAsset(type) {
      try {
        this.assetRequiredErrors = validateFields(
          this.newAsset,
          this.assetRequiredErrors
        );
        if (checkValidationErrors(this.assetRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEASSET" : "EDITASSET",
            this.newAsset
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el activo",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar el activo en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el activo la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el activo en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     * @param deleteMessage the delete message that the user will see
     */
    startDelete(type, functionOnConfirm, name, deleteMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalError.call(
        this,
        type,
        functionOnConfirm,
        name,
        deleteMessage
      );
    },
    async deleteAsset() {
      try {
        let response = await this.$store.dispatch("DELETEASSET", this.newAsset);
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se borró exitosamente el activo",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al borrar el activo",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al borrar el activo",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
