<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Vehículos </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Activos" icon="car">
        <AssetsList></AssetsList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import AssetsList from "../components/AssetsList";

// @ is an alias to /src
export default {
  name: "CompnayAssetsLists",
  components: {
    AssetsList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "assets");
  },
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETASSETS"));

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
